import React, {useEffect, useState} from 'react';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogOut from '../Modules/LogOut';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import {userAuth} from '../Modules/AuthConfigs';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import DataChart from '../Components/Charts/DataChart';
import useWindowDimensions from '../Modules/useWindowDimensions';
import '../Css/Pages/Statistics.css';
import { useTranslation } from 'react-i18next';

const Statistics = () => {

    const[menu , setMenu] = useState(true);
    const[userGardens, setUserGarden] = useState();
    const[error, setError] = useState(false);
    const[gardenId, setGardenId] = useState();
    const[noResults, setNoResults] = useState(false);
    const[token, setToken] = useState("");
    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }
    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        let mounted = true;
        const fetchData = async () => {
            try{
                const resG = await axios.get(BringUrl()+'/api/gardens', userAuth());
                if(mounted) {
                    setError(false);
                    console.log(resG.data)
                    setUserGarden(resG.data);
                    if(resG.data.length > 0){
                        setGardenId(resG.data[0]._id);
                    }else{
                        setNoResults(true);
                    }
                }
            }catch(err){
                if(mounted) {
                    console.log(err);
                    setError(true);
                }
            }
        };
        fetchData();
        return () => {
            mounted = false;
        }
    },[]);

    const changGarden = (e) =>{
        setGardenId(e.target.value);
    }

    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt="" /> {t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t("stats_title")}</h2>
                        <div className='privatemidrow'>
                            <div className='selectGarden'>
                                {
                                    userGardens &&
                                    <>
                                    <span>{t("stats_select")}</span>
                                    <select className='selectDrop' onChange={changGarden}>
                                        {
                                            userGardens.map((garden, key)=>(
                                                <option key={key} value={garden._id}>{garden.name}</option>
                                            ))
                                        }
                                    </select>
                                    </>
                                }
                            </div>
                            <div className='createGarden'>

                            </div>
                        </div>
                        <hr className="allHR"/>
                        <section className='privateGardenContainer'>
                            {
                                gardenId
                                ?<div className='chartContainer'>
                                    <DataChart gardenId={gardenId} />
                                </div>
                                :noResults
                                ?<span className='noResults'>{t("stats_noG")}</span>
                                :<CircularProgress />
                            }
                        </section>
                    </div>
                </section>
            </div>
        </>
    );
};


export default Statistics;