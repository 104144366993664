

export default function BringUrl(){

    //const endpoints = "http://127.0.0.1";
    const endpoints = window.location.protocol + "//" + window.location.hostname+""
    return endpoints;
}
export function UrlAPI(){
    const apis = "https://ardeusi.gr/api/";
    return apis;
}