import React, {useEffect, useState} from 'react';
import Ports from '../URLs/Ports.json';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth } from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import '../Css/CreateGarden.css';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogOut from '../Modules/LogOut';
import LogoutImg from '../Images/Logout.svg';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import BringUrlLocale from '../URLs/BringUrlLocale';
import { isAdmin } from '../Modules/AuthConfigs';
import useWindowDimensions from '../Modules/useWindowDimensions';
import { useTranslation } from 'react-i18next';

const UserInformation = () => {

    const[userData, setUserData] = useState();
    const[dataloaded, setDataLoaded] = useState(false);
    const[menu , setMenu] = useState(true);
    const[error, setError] = useState(false);
    const[password, setPassword] = useState("");
    const[confirmation, setConfirmation] = useState("");
    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }
    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        const initilFetch = async () =>{
            try{
                const res = await axios.get(BringUrl()+ "/api/user/" , userAuth());
                console.log(res.data);
                setUserData(res.data);
                setDataLoaded(true);
            }catch(err){
                console.log(err);
                setDataLoaded(false);
            }
        }
        initilFetch();
    },[]);

    const changeInfo = (e) =>{
        setUserData({...userData, [e.target.name]: e.target.value});
    }
    const sendData = async () =>{

        if(userData.username!=="" ){
            console.log(userData);
            if(password !== confirmation){
                setError("Passwords doesn't match each other");
            }else{
                try{
                    let toSend = userData;
                    toSend.password = password;
                    const res = await axios.post(BringUrl() + "/api/user/update" ,toSend, userAuth());
                    console.log(res.data);
                    setError("");
                    window.location.href = BringUrlLocale() + Ports.UI + "/dashboard/mygardens";
                }catch(err){
                    console.log(err);
                    setError("Something went wrong! Try again later!");
                }
            }
        }else{
            setError("There are empty fields!");
        }
        
    }
    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt="" /> {t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t("user_info")}</h2>
                    </div>
                    {
                        dataloaded 
                        ?
                        <>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_have")} {userData.points} {t("user_points")}!</span>
                            </div>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_thisis")} {isAdmin().admin ?"Administration":"User"} {t("user_acc")}</span>
                            </div>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_name")}</span>
                                <span className='addgardenfieldlabel'>{t("user_nameExpl")}</span>
                                <div className='addgardenfieldRest'>
                                    <input type="text" className="inputsG" placeholder='Your Name' name='username' value={userData.username} onChange={changeInfo}/>
                                </div>
                            </div>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_email")}</span>
                                <span className='addgardenfieldlabel'>{t("user_emailExpl")}</span>
                                <div className='addgardenfieldRest'>
                                    <input type="text" className="inputsG" placeholder='youremail@mail.com' value={userData.email} name='email' onChange={changeInfo}/>
                                </div>
                            </div>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_pass")}</span>
                                <span className='addgardenfieldlabel'>{t("user_passExpl")}</span>
                                <div className='addgardenfieldRest'>
                                    <input type="text" className="inputsG" placeholder='Put your new Password' value={password} name='password' onChange={(e)=>(setPassword(e.target.value))}/>
                                </div>
                                <div className='addgardenfieldRest'>
                                    <input type="text" className="inputsG" placeholder='Confirm your new Password' value={confirmation} name='confirmation' onChange={(e)=>(setConfirmation(e.target.value))}/>
                                </div>
                            </div>
                            <div className='addgardenfield'>
                                <span className='addgardenfieldtitle'>{t("user_level")}</span>
                                <span className='addgardenfieldlabel'>{t("user_levelExpl")}</span>
                                <div className='addgardenfieldRest'>
                                    <select className="inputsG"  name='userlevel' value={userData.userlevel} onChange={changeInfo}>
                                        <option value={"Novice"}>{t("user_nov")}</option>
                                        <option value={"Advanced"}>{t("user_adv")}</option>
                                        <option value={"Competent"}>{t("user_comp")}</option>
                                        <option value={"Experienced"}>{t("user_expe")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='errbuttonsCont'><span className={error!=""?'errorCreate':'hidden'}>{error}</span></div>
                            <div className='buttonsCont'><div onClick={sendData}>Update User</div></div>
                        </>
                        :<CircularProgress />
                    }
                </section>
            </div>
        </>
    );
};


export default UserInformation;