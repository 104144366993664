import { useState } from 'react';

export default function useToken() {

  console.log("in");
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken
      };
      
      const [token, setToken] = useState(getToken());

      const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
        console.log(token);

      };

      return {
        setToken: saveToken,
        token
      }

}