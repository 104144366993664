import Overview from '../../Images/Overview.svg';
import PublicGardens from '../../Images/PublicGardens.svg';
import Mygardens from '../../Images/Mygardens.svg';
import Settings from '../../Images/Settings.svg';
import Forecast from '../../Images/Forecast.svg';
import Chart from '../../Images/chart.svg';


export const sideData = [];

export const sideDataEN = [
    {
        title:"Overview",
        path: "/dashboard",
        image: Overview,
        userView: true
    },
    {
        title:"My Gardens",
        path: "/dashboard/mygardens",
        image: PublicGardens,
        userView: true
    },
    {
        title:"Public Gardens",
        path: "/dashboard/publicgardens",
        image: Mygardens,
        userView: true
    },
    {
        title:"Forecast",
        path: "/dashboard/forecast",
        image: Forecast,
        userView: true
    },
    {
        title:"Chart Data",
        path: "/dashboard/statistics",
        image: Chart,
        userView: true
    },
    {
        title:"Settings",
        path: "/dashboard/settings",
        image: Settings,
        userView: false
    }
]

export const sideDataGR = [
    {
        title:"Περίληψη",
        path: "/dashboard",
        image: Overview,
        userView: true
    },
    {
        title:"Οι κήποι μου",
        path: "/dashboard/mygardens",
        image: PublicGardens,
        userView: true
    },
    {
        title:"Δημόσιοι κήποι",
        path: "/dashboard/publicgardens",
        image: Mygardens,
        userView: true
    },
    {
        title:"Καιρός",
        path: "/dashboard/forecast",
        image: Forecast,
        userView: true
    },
    {
        title:"Διαγράμματα",
        path: "/dashboard/statistics",
        image: Chart,
        userView: true
    },
    {
        title:"Ρυθμίσεις",
        path: "/dashboard/settings",
        image: Settings,
        userView: false
    }
]