import React, {useEffect, useState} from 'react';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {userAuth} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogOut from '../Modules/LogOut';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import '../Css/Pages/MyGardens.css';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import GardenPrivate from '../Components/GardenPrivate';
import { isAdmin } from '../Modules/AuthConfigs';
import useWindowDimensions from '../Modules/useWindowDimensions';
import { useTranslation } from 'react-i18next';

const PrivateGardens = () => {

    const[menu , setMenu] = useState(true);
    const[userGardens, setUserGarden] = useState();
    const[error, setError] = useState(false);
    const[gardenId, setGardenId] = useState();
    const[noResults, setNoResults] = useState(false);
    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }

    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        let mounted = true;
        const fetchData = () => {
            axios.get(BringUrl()+'/api/gardens', userAuth()).then(res => {
                // do good things
                if(mounted) {
                    setError(false);
                    console.log(res.data)
                    setUserGarden(res.data);
                    if(res.data.length > 0){
                        setGardenId(res.data[0]._id);
                    }else{
                        setNoResults(true);
                    }
                }
                console.log(res.data);
            })
            .catch(err => {
                if(mounted) {
                    console.log(err);
                    setError(true);
                }
            })
        };
        fetchData();
        return () => {
            mounted = false;
        }
    },[]);

    const changGarden = (e) =>{
        setGardenId(e.target.value);
    }
    function gardenStatus(){
        const selected = userGardens.find((x)=>x._id === gardenId);
        console.log(selected);
        if(selected.public === true){
            return <span className='gardenSpecification'>{t("privateG_pub")}</span>;
        }
        return <span className='gardenSpecification'>{t("privateG_pri")}</span>;
    }
    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt="" /> {t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t("privateG_myG")}</h2>
                        <div className='privatemidrow'>
                            <div className='selectGarden'>
                                {
                                    (userGardens && gardenId) &&
                                    <>
                                    <span>{t("publicG_sel")}</span>
                                    <select className='selectDrop' onChange={changGarden}>
                                        {
                                            userGardens.map((garden, key)=>(
                                                <option key={key} value={garden._id}>{garden.name}</option>
                                            ))
                                        }
                                    </select>
                                    {isAdmin().admin && gardenStatus()}
                                    </>
                                }
                            </div>
                            <div className='createGarden'>
                                {!noResults && <Link to={"/dashboard/editgarden/"+gardenId} style={{textDecoration:"none"}}><div>{t("privateG_ed")}</div></Link>}
                                <Link to={"/dashboard/creategarden"} style={{textDecoration:"none"}}><div>{t("privateG_create")}</div></Link>
                            </div>
                        </div>
                        <hr className="allHR"/>
                        <section className='privateGardenContainer'>
                            {
                                userGardens && gardenId
                                ?<>
                                <GardenPrivate gardenId={gardenId} />
                                </>
                                :error
                                ?<span className='somethingWrong'>Something went wrong.</span>
                                :noResults
                                ?<span className='noResults'>{t("privateG_noG")}</span>
                                :<div className="circularProgressContainer"><CircularProgress size="60px" /></div>
                            }
                        </section>
                    </div>
                </section>
            </div>
        </>
    );
};


export default PrivateGardens;