import React,{useState, useEffect} from 'react';
import arrowB from "../Images/arrowb.svg";
import arrowF from "../Images/arrowf.svg";
import plus from "../Images/plus.svg";
import minus from "../Images/minus.svg";
import update from "../Images/update.svg";
import ActionModal from './Modals/ActionModal';
import addC from "../Images/addC.svg";
import burn from "../Images/burn.svg";
import flower from "../Images/flower.svg";
import { useTranslation } from 'react-i18next';

const GardenActions = (props) => {

    const[typeList, setTypeList] = useState();
    const [step, setStep] = useState(0);
    const [compost, setCompost] = useState(0);
    const[actions, setActions] = useState({
        withered: 0,
        plant: 0,
        harvested: 0
    })
    const[senModal, setSendModal] = useState(false);
    const { t, i18n } = useTranslation();

    const[wtSupply, setWtSuplly] = useState(false);
    const[wtNetwork, setWtNetwork] = useState(false);
    
    const [toSend, setToSend] = useState();

    const changeStep = (mov) =>{
        if(mov === "forw"){
            setStep((step+1)%typeList.length);
        }else{
            if(step-1<0){
                setStep(typeList.length - Math.abs(step-1));
            }else{
                setStep(Math.abs(step-1)%typeList.length);
            }
        }

    }
    console.log(props.data.type);
    useEffect(()=>{
        setTypeList(props.data.type);
    },[props.data.type]);


    const changeCrop = (type, action) =>{
        switch(type){
            case 'withered':
                if(action === 'add'){
                    setActions({...actions, withered:actions.withered+1});
                }else{
                    setActions({...actions, withered:actions.withered-1});
                }
                break;
            case 'plant':
                if(action === 'add'){
                    setActions({...actions, plant:actions.plant+1});
                }else{
                    setActions({...actions, plant:actions.plant-1});
                }
                break;
            default:
                if(action === 'add'){
                    setActions({...actions, harvested:actions.harvested+1});
                }else{
                    setActions({...actions, harvested:actions.harvested-1});
                }
                break;
        }
    }

    function blockAdd(){
        if(typeList[step].quantity + actions.plant - actions.harvested - actions.withered >0){
            return true;
        }else{
            return false;
        }
    }

    const updateB = (type) =>{
        if(wtNetwork!==false || wtSupply!==false || (actions.plant + actions.harvested + actions.withered > 0) || !(actions.plant ===0 && actions.harvested ===0 && actions.withered ===0 ) || compost!==0 ){
            switch(type){
                case "crop":
                    let total = typeList[step].quantity + actions.plant - actions.harvested - actions.withered;
                    if(total<0){
                        total = 0;
                    }
                    setToSend({
                        type: "crop",
                        name: typeList[step].name,
                        actions: total
                    });
                    break;
                case "compost":
                    setToSend({
                        type: "compost",
                        name: typeList[step].name,
                        actions: {}
                    });
                    break;
                default:
                    setToSend({
                        type: "watertank",
                        name: typeList[step].name,
                        actions: {}
                    });
                    break;
            }
            
            trigModal();
        }
        
    }
    const trigModal = () =>{
        setSendModal(!senModal);
    }
    const setCompSelector = (num) =>{
        if(compost === num){
            setCompost(0);
        }else{
            setCompost(num);
        }
    }
    return (
        typeList 
        ?
        <>
            <span className='notificationsTitle'>{t("action_activ")}</span>
            <div className='rowWrapper'>
                <div className='actionBox'>
                    <span className='actionTitle'>{t("action_crop")}</span>
                    <div className={typeList.length>1?'overallCount justifySB':'overallCount justifyC'}>
                        {typeList.length>1 && <img alt='swipe Left' src={arrowB} onClick={()=>(changeStep("bck"))} />}
                        <div className='totalNum'>
                            <div>
                                {typeList[step].quantity}
                            </div>
                            <span>{typeList[step].name}</span>
                        </div>
                        {typeList.length>1 && <img alt='swipe Right' src={arrowF} onClick={()=>(changeStep("forw"))} />}
                    </div>
                    <div className='actionsPanel'>
                        <div className='selector'>
                            <div className='actionRow'>
                                <label>{t("action_Withered")}</label>
                                <div className='actNums'>
                                    <div className='arrowWidth'>{actions.withered>0 && <img alt='subtract' className='actionSymb' src={minus} onClick={()=>(changeCrop('withered','rmv'))} />}</div>
                                    <div className='numWidth'>{actions.withered}</div>
                                    <div className='arrowWidth'>{blockAdd() && <img alt='add' className='actionSymb' src={plus} onClick={()=>(changeCrop('withered','add'))} />}</div>
                                </div>
                            </div>
                            <div className='actionRow'>
                                <label>{t("action_Plant")}</label>
                                <div className='actNums'>
                                    <div className='arrowWidth'>{actions.plant>0 && <img alt='subtract' className='actionSymb' src={minus} onClick={()=>(changeCrop('plant','rmv'))} />}</div>
                                    <div className='numWidth'>{actions.plant}</div>
                                    <div className='arrowWidth'><img alt='add' className='actionSymb' src={plus} onClick={()=>(changeCrop('plant','add'))} /></div>
                                </div>
                            </div>
                            <div className='actionRow'>
                                <label>{t("action_Harvested")}</label>
                                <div className='actNums'>
                                    <div className='arrowWidth'>{actions.harvested>0 && <img alt='subtract' className='actionSymb' src={minus} onClick={()=>(changeCrop('harvested','rmv'))} />}</div>
                                    <div className='numWidth'>{actions.harvested}</div>
                                    <div className='arrowWidth'>{blockAdd() && <img alt='add' className='actionSymb' src={plus} onClick={()=>(changeCrop('harvested','add'))} />}</div>
                                </div>
                            </div>
                        </div>
                        <div className='actionUpdate' onClick={()=>updateB("crop")} ><img alt='' src={update} /> {t("butt_update")}</div>
                    </div>
                </div>
                <div className='actionBox'>
                    <span className='actionTitle'>{t("action_comp")}</span>
                    <div className='actionsPanel'>
                        <div className='selector'>
                            <div className='selectOption'>
                                <span>{t("action_add")}</span>
                                <div className={compost===1?'optionCSelected':'optionC'} onClick={()=>(setCompSelector(1))} >{compost !== 1 && <img alt='' src={addC}  />} {t("butt_add")}</div>
                            </div>
                            <div className='selectOption'>
                                <span>{t("action_use")}</span>
                                <div className={compost===2?'optionCSelected':'optionC'} onClick={()=>(setCompSelector(2))}>{compost !== 2 && <img alt='' src={flower}  />} {t("butt_usage")}</div>
                            </div>
                            <div className='selectOption'>
                                <span>{t("action_burnt")}</span>
                                <div className={compost===3?'optionCSelected':'optionC'} onClick={()=>(setCompSelector(3))}>{compost !== 3 && <img alt='' src={burn} />} {t("butt_burn")}</div>
                            </div>
                        </div>
                        <div className='actionUpdate' onClick={()=>updateB("compost")} ><img alt='' src={update} /> {t("butt_update")}</div>
                    </div>
                </div>
                <div className='actionBox'>
                    <span className='actionTitle'>{t("action_wt")}</span>
                    <div className='actionsPanel'>
                        <div className='selector'>
                            <div className='selectOption'>
                                <span>{t("action_net")}</span>
                                <div className={wtNetwork ?'optionCSelected':'optionC'} onClick={()=>(setWtNetwork(!wtNetwork))} >{t("butt_use")}</div>
                            </div>
                            <div className='selectOption'>
                                <span>{t("action_system")}</span>
                                <div className={wtSupply ?'optionCSelected':'optionC'} onClick={()=>(setWtSuplly(!wtSupply))}>{t("butt_use")}</div>
                            </div>
                        </div>
                        <div className='actionUpdate' onClick={()=>updateB("watertank")} ><img alt='' src={update} /> {t("butt_update")}</div>
                    </div>
                </div>
            </div>
            <ActionModal show={senModal}  dataToPost={toSend} hide={trigModal} setTrig={props.setTrig} trigUpdate={props.trigUpdate} gardenId={props.data._id} />
        </>
        :<></>
    );
};


export default GardenActions;