import './App.css';
import { useState, useEffect, useMemo} from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';import './Css/Menu.css'
import Login from './Components/Login';
import Landing from './Pages/Landing';
import Error from './Pages/Error';
import ProtectedRoute from './Modules/ProtectedRoute';
import Dashboard from './Pages/Dashboard';
import PublicGardens from './Pages/PublicGardens';
import Forecast from './Pages/Forecast';
import RuleSettings from './Pages/RuleSettings';
import PrivateGardens from './Pages/PrivateGardens';
import CreateGarden from './Pages/CreateGarden';
import EditGarden from './Pages/EditGarden';
import UserInformation from './Pages/UserInformation';
import Statistics from './Pages/Statistics';
import { Context } from './Context/context';


function App() {
  

  if(useWindowSize().width<600){
    //return <SizeError />;
  }
 /*if(!token) {
    return <Login setToken={setToken} setTokenAPI={setTokenAPI} />
  }
  console.log(token);*/

  const [lang, setLang] = useState('gr');
  const value = useMemo(
    () => ({ lang, setLang }), 
    [lang]
  );

  return(
    <Context.Provider value={value}>
      <Router >
        <Switch>
          <Route exact path='/'  component={Landing} />
          <Route exact path='/signin'  component={Login} />
          <ProtectedRoute exact path='/dashboard'  component={Dashboard}/>
          <ProtectedRoute exact path='/dashboard/publicgardens'  component={PublicGardens}/>
          <ProtectedRoute exact path='/dashboard/mygardens'  component={PrivateGardens}/>
          <ProtectedRoute exact path='/dashboard/creategarden' component={CreateGarden}/>
          <ProtectedRoute exact path='/dashboard/editgarden/:gardenId'  component={EditGarden}/>
          <ProtectedRoute exact path='/dashboard/forecast'  component={Forecast}/>
          <ProtectedRoute exact path='/dashboard/settings'  component={RuleSettings}/>
          <ProtectedRoute exact path='/dashboard/userInfo'  component={UserInformation}/>
          <ProtectedRoute exact path='/dashboard/statistics'  component={Statistics}/>
          <Route path='*' component={Error} />
        </Switch>
      </Router>
    </Context.Provider>
    
  );
}


function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const SizeError = () =>{
  return (<div className="SizeError">
    <h3>Size Error</h3>
    <span>This site is not available for devices with width under 600px</span>
  </div>);
}


export default App;

