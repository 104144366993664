import React, {useEffect, useState} from 'react';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../Modules/AuthConfigs';
import light from '../Images/lightbulb.svg';
import timestamp from '../Images/timestamp.svg';
import { useTranslation } from 'react-i18next';

const NotificationsDrop = (props) => {
    const { t, i18n } = useTranslation();

    function bringDate(date){
        const d = new Date(date);
        return d.toLocaleDateString();
    }
    const completedNot =async (notificationID) =>{

        try{
            console.log(notificationID);
            const res = await axios.post(BringUrl() + "/api/actions/deletenotification/"+notificationID ,{}, userAuth());
            props.setDeleteN(props.deleteNot+1);
            console.log(res.data);
        }catch(err){
            console.log(err);
        }
    }

    return (
        <>
            <span className='notificationsTitle'>{t("not_t")}</span>
            <div className='notificContainer'>
                {
                    props.notifArray &&
                    <>
                    {
                        props.notifArray.length === 0
                        ?<div className='noNotes'>{t("not_mess")}</div>
                        :
                        <>
                        {
                        props.notifArray.map((notification, key)=>(
                            <div className='singleNotification' key={key}>
                                <div className='topNotific'>
                                    <img src={light} alt="" />
                                    <div className='timestampContainer'><img src={timestamp} alt="" className='marginR5'/> {bringDate(notification.date)}</div>
                                </div>
                                <div className='midNotific'>{notification.note}</div>
                                <div className='bottNotific'><div onClick={()=>(completedNot(notification._id))}>{t("not_comp")}</div></div>
                            </div>
                        ))
                        }
                        </>
                    }
                    </>
                    
                }
            </div>
        </>
    );
};


export default NotificationsDrop;