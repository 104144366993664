import React, {useEffect , useState} from 'react';
import LogOut from '../Modules/LogOut';import '../Css/General.css';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import '../Css/Pages/Settings.css';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import BringUrl from '../Modules/BringUrl';
import {userAuth} from '../Modules/AuthConfigs';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowDimensions from '../Modules/useWindowDimensions';

const RuleSettings = () => {

    const[menu , setMenu] = useState(true);
    const[loader , setLoader] = useState(false);
    const[data, setData] = useState();
    const[error , setError] = useState("");
    const[token , setToken] = useState("");
    const [cred, setCred]= useState({
        email:"",
        password:""
    })
    const { height, width } = useWindowDimensions();

    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        let mounted = true;
        setLoader(false);
        const fetchData = async () => {
            axios.get(BringUrl()+'/api/admin', userAuth()).then(res => {
                // do good things
                if(mounted) {
                    setData(res.data);
                    setLoader(true);
                    console.log(res.data);
                }
            })
            .catch(err => {
                if(mounted) {
                    setError("Error on loading the data");
                    setLoader(true);
                }
            })
        };
        fetchData();
        return () => {
            mounted = false;
        }
    },[])

    const execRuleEngine = async (forr) =>{
        try{
            const res = await axios.post(BringUrl()+'/api/admin/executeruleengine/'+forr, {} ,userAuth());
            console.log(res);
        }catch(err){

        }
    }
    const changeCred = (e) =>{
        setCred({...cred, [e.target.name]:e.target.value});
    }
    const UpdateCredentials = async () =>{
        if(cred.email.replace(" ", "") !== "" && cred.password.replace(" ", "") !== ""){
            try{
                const res = await axios.post(BringUrl()+'/api/admin/updatecredentials', {cred} ,userAuth());
                console.log(res);
            }catch(err){
    
            }
        }
    }
    const updateWeatherToken = async () =>{
        const sendData = {
            token: token
        }
        if(token.replace(" ", "")){
            try{
                const res = await axios.post(BringUrl()+'/api/admin/weatherAPIToken', {sendData} ,userAuth());
                console.log(res);
            }catch(err){
    
            }
        }
    }
    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }

    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt='' /> Log Out</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">Settings</h2>
                    </div>
                    <section>
                        
                    </section>
                    <section className='buttonsSettings'>
                        <h3>Execute Rule Engine</h3>
                        <div className='buttonRow'>
                            <div onClick={()=>execRuleEngine("Novice")} className="actionbuttons">Novice</div><div onClick={()=>execRuleEngine("Beginner")} className="actionbuttons">Beginner</div>
                            <div onClick={()=>execRuleEngine("Competent")} className="actionbuttons">Competent</div><div onClick={()=>execRuleEngine("Experienced")} className="actionbuttons">Experienced</div>
                        </div>  
                    </section>
                    <section className='editextcredSection'>
                        <h3>Update Credentials for external API</h3>
                        <span>Email</span>
                        <input type="text" className="inputsG" name='email' placeholder={"email"} onChange={changeCred} />
                        <span>Password</span>
                        <input type="text" className="inputsG" name='password' placeholder={"password"}  onChange={changeCred}/>
                        <div onClick={UpdateCredentials} className="actionbuttons" >Update</div>
                    </section>
                    <section className='editextcredSection'>
                        <h3>Update token for Weather API</h3>
                        <input type="text" className="inputsG" name='token' placeholder={"token"} onChange={(e)=>(setToken(e.target.value))} />
                        <div onClick={updateWeatherToken} className="actionbuttons" >Update</div>
                    </section>
                </section>
            </div>
        </>
    );
};

RuleSettings.propTypes = {};

export default RuleSettings;