import React, {useState, useEffect} from 'react';
import '../../Css/Modals/DeleteGarden.css';
import BringUrl from '../../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';


const ActionModal = (props) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [dataOK, setDataOK] = useState(false);

    useEffect(() => {
        if(!props.show){
            setLoading(true);
            setDataOK(true);
            return null;
        }
        let mounted = true;
        setLoading(true);
        setDataOK(true);
        if(props.show){
            console.log(props.dataToPost);
            const initialLoad = async () =>{
                try{
                    const res = await axios.post(BringUrl() + "/api/actions/garden/"+props.gardenId ,props.dataToPost, userAuth());
                    if(mounted) {
                        console.log(res);
                        setLoading(false);
                        props.setTrig(props.trigUpdate+1);
                    }
                }catch(err){
                    if(mounted) {
                        setLoading(false);
                        setDataOK(false);
                    }
                }
            }
            initialLoad()
        }
        return () =>{
            mounted = false;
        }
    },[props.show]);

    return (
        <div className={props.show  ? "modal display-block" : "modal display-none"}>
            <section className="modal-main">
                <div className="senddataContainer">
                    {loading ?<h3>{t("modal_Loading")}... </h3>:<h3>{t("modal_sresponse")}</h3>}
                    {
                        loading
                        ?<CircularProgress />
                        :dataOK
                        ?<div className='columnPanel'><span className='succ'>{t("not_comp")}</span><div onClick={()=>{props.hide()}} className="leave">{t("modal_Leave")}</div></div>
                        :<div className='columnPanel'><span className='err'>{t("modal_error")}</span><div onClick={()=>{props.hide()}} className="leave">{t("modal_Leave")}</div></div>
                    }
                </div>
            </section>
        </div>
    );
};


export default ActionModal;