import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'

const MapGarden = (props) => {

    return (
            <MapContainer center={props.position} zoom={17} scrollWheelZoom={true} className="MapContainer" >
                <ChangeMapView coords={props.position} />
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={props.position}>
                    <Popup>
                    {props.popUpName} <br /> {props.popUpType}
                    </Popup>
                </Marker>
            </MapContainer>
    );
};

function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
  
    return null;
}
  
export default MapGarden;