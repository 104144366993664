import React, {useState, useEffect} from 'react';
import '../../Css/Modals/DeleteGarden.css';
import BringUrl from '../../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const DeleteGarden = (props) => {

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [res, setRes] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(()=>{
        setRes(false);
        setLoading(false);
        setLoaded(false);
    },[props.show])
    const actionD = async (act) =>{
        if(act){
            setLoading(true);
            try{
                const res = await axios.post(BringUrl()+ props.postTo ,{}, userAuth());
                console.log(res);
                if(res.status !== 200){
                    setRes(false);
                }else{
                    setRes(true);
                }
                setLoading(false);
                setLoaded(true);
                props.setSuccDelete(true);
            }catch(err){
                console.log(err);
                setRes(false);
                setLoading(false);
                setLoaded(true);
                props.setSuccDelete(false);
            }
        }else{
            props.hideModal();
        }
    }
    return (
        <div className={props.show  ? "modal display-block" : "modal display-none"}>
            <section className="modal-main">
                <div className="senddataContainer">
                    {!loaded?<h3>{t("modal_delete")}</h3>:<h3>{t("modal_sresponse")}</h3>}
                    {
                        !loaded
                        ?loading
                        ?<CircularProgress />
                        :<div className='popButtons'>
                            <div onClick={()=>{actionD(true)}}>{t("universal_yes")}</div>
                            <div onClick={()=>{actionD(false)}}>{t("universal_no")}</div>
                        </div>
                        :<div className='resMessage'>
                            {res?<span className='succ'>{t("modal_succ")}</span>:<span className='err'>{t("modal_error")}</span>}
                            <div onClick={()=>{props.hideModal()}} className="leave">{t("modal_Leave")}</div>
                        </div>
                    }

                </div>
            </section>
        </div>
    );
};


export default DeleteGarden;