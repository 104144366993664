import React,{useState , useEffect} from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const[isAuthenticated,setisAuthenticated]=useState(sessionStorage.getItem("token"));

  useEffect(()=>{
    setisAuthenticated(sessionStorage.getItem("token"));
  },[])
  return (
    <>
      {
        isAuthenticated 
        ?
        <Route {...restOfProps} render={ props => <Component {...restOfProps} {...props} /> } />
        :
        <Redirect to="/" />
      }
    </>
  );

}

export default ProtectedRoute;