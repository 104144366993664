import React, {useEffect , useState} from 'react';
import LogOut from '../Modules/LogOut';
import '../Css/Pages/Forecast.css';
import '../Css/General.css';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import info from '../Images/info.svg';
import MapButton from '../Images/MapButton.svg';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import BringUrl from '../Modules/BringUrl';
import {userAuth} from '../Modules/AuthConfigs';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowDimensions from '../Modules/useWindowDimensions';
import battery from '../Images/forecast/battery.svg';
import DailyRainfall from '../Images/forecast/DailyRainfall.svg';
import HourlyRainfall from '../Images/forecast/HourlyRainfall.svg';
import humidity from '../Images/forecast/humidity.svg';
import lightintensity from '../Images/forecast/lightintensity.svg';
import soilmoisture from '../Images/forecast/soilmoisture.svg';
import uvpower from '../Images/forecast/uvpower.svg';
import windSpeed from '../Images/forecast/wind_speed.svg';
import WindDirection from '../Images/forecast/WindDirection.svg';
import temperature from '../Images/forecast/temperature.svg';
import { useTranslation } from 'react-i18next';

const Forecast = () => {

    const[menu , setMenu] = useState(true);
    const[station , setStation] = useState("53");
    const[loader , setLoader] = useState(false);
    const[tempData , setTempData] = useState();
    const[error , setError] = useState("");
    const { t, i18n } = useTranslation();

    const { height, width } = useWindowDimensions();

    useEffect(()=>{
        let mounted = true;
        if(width<680){
            setMenu(false);
        }
        setLoader(false);
        const fetchData = async () => {
            axios.get(BringUrl()+'/api/forecast/'+station, userAuth()).then(res => {
                // do good things
                if(mounted) {
                    setTempData(res.data);
                    setLoader(true);
                    console.log(res.data);
                }
            })
            .catch(err => {
                if(mounted) {
                    setError("Error on loading the data");
                    setLoader(true);
                }
            })
        };
        fetchData();
        return () => {
            mounted = false;
        }
    },[station])

    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }
    function bringName(id){
        switch(id){
            case "53":
                return "Μύλος Ματσόπουλου (Μετεωρ.)";
            case "57":
                return "Σταθμός Πρίνος";
            case "58":
                return "Ecogaia Farm (Λογγάκι)";
        }
    }

    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg}  alt=""  className="sideImage" />{t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t("forecast_weather")}</h2>
                        <span className="ForecastSubTitle">{t("forecast_select")}</span>
                        <hr className="allHR"/>
                        <div className="forecastInfo"><img src={info} alt="" /><span>{t("forecast_choose")}</span></div>
                        <div className="forecastInfo">
                            <select className="stationDrop" onChange={(e)=>(setStation(e.target.value))} >
                                <option value="53">{t("forecast_matso")}</option>
                                <option value="57">{t("forecast_prinos")}</option>
                                <option value="58">{t("forecast_ecogaia")}</option>
                            </select>
                            <img src={MapButton}  alt=""  className="stationIMG" />
                        </div>
                    </div>
                    {
                        loader && tempData
                        ?
                        <section className='windForContainer'>
                            <div className='agValues'>
                                <div className='columnWrap'>
                                    <div className="foreValue"><span>{tempData[0].label}</span><div className='underTitle'><div className='circle'><img src={temperature} alt="" className="forecastImg"/></div><span>{tempData[0].value} {tempData[0].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[1].label}</span><div className='underTitle'><div className='circle'><img src={humidity} alt="" className="forecastImg"/></div><span>{tempData[1].value} {tempData[1].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[2].label}</span><div className='underTitle'><div className='circle'><img src={DailyRainfall} alt="" className="forecastImg"/></div><span>{tempData[2].value} {tempData[2].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[3].label}</span><div className='underTitle'><div className='circle'><img src={HourlyRainfall} alt="" className="forecastImg"/></div><span>{tempData[3].value} {tempData[3].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[4].label}</span><div className='underTitle'><div className='circle'><img src={windSpeed} alt="" className="forecastImg"/></div><span>{tempData[4].value} {tempData[4].unit}</span></div></div>
                                </div>
                                <div className='columnWrap'>
                                    <div className="foreValue"><span>{tempData[5].label}</span><div className='underTitle'><div className='circle'><img src={WindDirection} alt="" className="forecastImg"/></div><span>{tempData[5].value} {tempData[5].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[6].label}</span><div className='underTitle'><div className='circle'><img src={lightintensity} alt="" className="forecastImg"/></div><span>{tempData[6].value} {tempData[6].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[7].label}</span><div className='underTitle'><div className='circle'><img src={uvpower} alt="" className="forecastImg"/></div><span>{tempData[7].value} {tempData[7].unit}</span></div></div>
                                    <div className="foreValue"><span>{tempData[8].label}</span><div className='underTitle'><div className='circle'><img src={soilmoisture} alt="" className="forecastImg"/></div><span>{tempData[8].value} {tempData[8].unit}</span></div></div>
                                </div>
                            </div>
                            <iframe className='forecastwindy' src="https://embed.windy.com/embed2.html?lat=39.333&lon=21.847&detailLat=39.381&detailLon=21.847&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1" frameBorder="0"></iframe>
                        </section>
                        
                        :<div className="circularP"><CircularProgress /></div>
                    }
                    

                </section>
            </div>
        </>
    );
};


export default Forecast;