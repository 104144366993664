import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './Translations/translationEN.json';
import translationGR from './Translations/translationGR.json';


const resources = {
    en:{
        translation: translationEN
    },
    gr:{
        translation: translationGR
    }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: "gr", // if you're using a language detector, do not define the lng option
    keySeparator:false,

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;