
/*
export default function AuthConfigs(){

    return({
        headers: { Authorization: `Bearer `+JSON.parse(sessionStorage.getItem('token')) }
    })
}
export function AuthAPI(){
    return({
        headers: { Authorization: `Bearer `+JSON.parse(sessionStorage.getItem('access_token')) }
    });
}
*/

//Most Used
export function userAuth(){
    return({
        headers: { 'token': JSON.parse(sessionStorage.getItem('token')).token }
    });
}

export function isAdmin(){
    return({
        admin: JSON.parse(sessionStorage.getItem('token')).admin 
    });
}