import React, { useEffect, useState } from 'react';
import watertank from '../Images/watertank.svg';
import bucket from '../Images/bucket.svg';
import cloudy from '../Images/cloudy.svg';
import crop from '../Images/crop.svg';
import { useTranslation } from 'react-i18next';


const InformationGarden = (props) => {
    const { t, i18n } = useTranslation();

    // Check if data props are available and not an "Error" string before rendering each section
    const showWeatherSection = props.data.soilMoisture !== undefined && props.data.soilMoisture !== "Error";

    const showCropSection = props.data.cropMoisture !== undefined && props.data.cropMoisture !== "Error";

    const showCompostSection =   props.data.compostTemperature !== "Error" && props.data.compostMoisture !== "Error";

    const showWaterTankSection = props.data.waterTank !== undefined && props.data.waterTank !== "Error" &&
        props.data.TDS !== undefined && props.data.TDS !== "Error";

        // console.log("weather",showWeatherSection);
        // console.log("crop",showCropSection);
        // console.log("compost",showCompostSection);

    return (
        <>
            {showWeatherSection && (
                <section className='gardenInfoDataContainer colorWeather'>
                    <img src={cloudy} alt="" />
                    <span className='infodataTitle'>{t("info_Weather")}</span>
                    <hr />
                    <section className='infodataCont'>
                        <div className='infodataCol'>
                            <div className='singleInfoContainer'>
                                <span>{t("info_Soil")}</span>
                                <span>{props.data.soilMoisture} %</span>
                            </div>
                            <div className='singleInfoContainer'>
                                <span>{t("info_Temp")}</span>
                                <span>{props.data.temperature} C</span>
                            </div>
                            <div className='singleInfoContainer'>
                                <span>{t("info_AitHum")}</span>
                                <span>{props.data.airHumidity} %</span>
                            </div>
                        </div>
                        <div className='infodataCol'>
                            <div className='singleInfoContainer'>
                                <span>{t("info_Wind")}</span>
                                <span>{props.data.windSpeed} km/h</span>
                            </div>
                            <div className='singleInfoContainer'>
                                <span>{t("info_Light")}</span>
                                <span>{props.data.lightIntensity} watt/m^2</span>
                            </div>
                            <div className='singleInfoContainer'>
                                <span>{t("info_Prec")}</span>
                                <span>{props.data.probabilityOfPrecipitation} %</span>
                            </div>
                        </div>
                    </section>
                </section>
            )}

            {showCropSection && (
                <section className='gardenInfoDataContainer colorCompost'>
                    <img src={crop} alt="" />
                    <span className='infodataTitle'>{t("createG_crop")}</span>
                    <hr />
                    <div className='singleInfoContainer'>
                        <span>{t("info_Soil")}</span>
                        <span>{props.data.cropMoisture} %</span>
                    </div>
                </section>
            )}

            {showCompostSection && (
                <section className='gardenInfoDataContainer colorCrop'>
                    <img src={bucket} alt="" />
                    <span className='infodataTitle'>{t("info_Compost")}</span>
                    <hr />
                    <div className='singleInfoContainer'>
                        <span>{t("info_Temp")}</span>
                        <span>{props.data.compostTemperature} C</span>
                    </div>
                    <div className='singleInfoContainer'>
                        <span>{t("info_Moisture")}</span>
                        <span>{props.data.compostMoisture} %</span>
                    </div>
                </section>
            )}

            {showWaterTankSection && (
                <section className='gardenInfoDataContainer colorTank'>
                    <img src={watertank} alt="" />
                    <span className='infodataTitle'>{t("info_WT")}</span>
                    <hr />
                    <div className='singleInfoContainer'>
                        <span>{t("info_WL")}</span>
                        <span>{props.data.waterTank} lt</span>
                    </div>
                    <div className='singleInfoContainer'>
                        <span>{t("info_tds")}</span>
                        <span>{props.data.TDS} ppm</span>
                    </div>
                </section>
            )}
        </>
    );
};


export default InformationGarden;