import React, {useEffect, useState} from 'react';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {userAuth , isAdmin} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogOut from '../Modules/LogOut';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import '../Css/CreateGarden.css';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import addcircle from '../Images/addcircle.svg';
import remove from '../Images/remove.svg';
import ticked from "../Images/ticked.svg";
import FindLocationMap from '../Components/FindLocationMap';
import Ports from '../URLs/Ports.json';
import BringUrlLocale from '../URLs/BringUrlLocale';
import useWindowDimensions from '../Modules/useWindowDimensions';
import { useTranslation } from 'react-i18next';


const CreateGarden = () => {

    console.log(isAdmin().admin);
    const[menu , setMenu] = useState(true);
    const[error, setError] = useState(false);
    const { t, i18n } = useTranslation();
    const[vegOption, setVegOption] = useState({
        name:"",
        quantity:0
    });
    const[countlist, setCountlist] = useState(0);
    const[vegArray, setVegArray] = useState([]);
    const [location, setLocation] = useState();
    const[title, setTitle] = useState("");
    const[ publicG, setPublicG] = useState(false);
    const[waterTankLt, setWaterTankLt] = useState("");
    const[loaded, setLoaded]=useState(false);
    const[sensors, setSensors] = useState({
        cropDevice:"",
        waterTankDevice:"",
        compostDevice:"",
        weatherDevice:"58",
        soilMoisture:{
            node_id:"78",
            sensor_id:"614"
        },
        airHumidity:{
            node_id:"78",
            sensor_id:"605"
        },
        temperature:{
            node_id:"78",
            sensor_id:"604"
        },
        propabilityOfPrecipitation:{
            node_id:"",
            sensor_id:""
        },
        lightIntensity:{
            node_id:"78",
            sensor_id:"610"
        },
        cropMoisture:{
            node_id:"",
            sensor_id:""
        },
        compostTemperature:{
            node_id:"",
            sensor_id:""
        },
        compostMoisture:{
            node_id:"",
            sensor_id:""
        },
        TDS:{
            node_id:"",
            sensor_id:""
        },
        waterTank:{
            node_id:"",
            sensor_id:""
        },
        windSpeed:{
            node_id:"78",
            sensor_id:"607"
        }
    })
    const { height, width } = useWindowDimensions();


    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }

    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        const initilaLoad = async () =>{
            function showPosition(position) {
                console.log("inShow")
                console.log(position);
                setLocation([position.coords.latitude, position.coords.longitude]);
              }
              function err() {
                setLocation(['39.549179024306255', '21.76566495339256']);
              }

              console.log(navigator.geolocation);
            if (navigator.geolocation) {
                console.log("navigator");
                navigator.geolocation.getCurrentPosition(showPosition, err);
            }else{
                console.log("no navigator");
                setLocation(['39.549179024306255', '21.76566495339256']);
            }
            setLoaded(true);
        }
        initilaLoad();
    },[]);

    const sendData = async () =>{
        console.log(title,waterTankLt, vegOption, location[0], location[1], sensors);
        if(title!==""  && vegArray.length>0 && String(location[0])!==""  && String(location[1])!=="" && waterTankLt!==""){
            const send = {
                name: title,
                type: vegArray,
                coordinates: {
                    lat: String(location[0]),
                    lon: String(location[1])
                },
                public: publicG,
                sensors: sensors,
                waterTankLt: parseInt(waterTankLt)
            }
            try{
                const res = await axios.post(BringUrl() + "/api/gardens/creategarden",send, userAuth());
                console.log(res.data);
                setError("");
                window.location.href = BringUrlLocale()+ Ports.UI+"/dashboard/mygardens";
            }catch(err){
                console.log(err);
            }
        }else{
            setError("There are empty fields!");
        }
        
    }
    const changeSensor = (e) =>{
        const variable = String(e.target.name).slice(0, 4);
        const type = String(e.target.name).charAt(4);
        switch(variable){
            case "soil":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, soilMoisture:{
                            ...prevSensors.soilMoisture, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, soilMoisture:{
                            ...prevSensors.soilMoisture, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "airH":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, airHumidity:{
                            ...prevSensors.airHumidity, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, airHumidity:{
                            ...prevSensors.airHumidity, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "temp":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, temperature:{
                            ...prevSensors.temperature, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, temperature:{
                            ...prevSensors.temperature, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "prec":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, propabilityOfPrecipitation:{
                            ...prevSensors.propabilityOfPrecipitation, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, propabilityOfPrecipitation:{
                            ...prevSensors.propabilityOfPrecipitation, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "ligh":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, lightIntensity:{
                            ...prevSensors.lightIntensity, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, lightIntensity:{
                            ...prevSensors.lightIntensity, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "croM":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, cropMoisture:{
                            ...prevSensors.cropMoisture, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, cropMoisture:{
                            ...prevSensors.cropMoisture, sensor_id:String(e.target.value)
                        }
                    }))
                }
                break;
            case "comT":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, compostTemperature:{
                            ...prevSensors.compostTemperature, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, compostTemperature:{
                            ...prevSensors.compostTemperature, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "comM":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, compostMoisture:{
                            ...prevSensors.compostMoisture, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, compostMoisture:{
                            ...prevSensors.compostMoisture, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "TDSs":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, TDS:{
                            ...prevSensors.TDS, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, TDS:{
                            ...prevSensors.TDS, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "tank":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, waterTank:{
                            ...prevSensors.waterTank, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, waterTank:{
                            ...prevSensors.waterTank, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "pres":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, pressure:{
                            ...prevSensors.pressure, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, pressure:{
                            ...prevSensors.pressure, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "winS":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, windSpeed:{
                            ...prevSensors.windSpeed, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, windSpeed:{
                            ...prevSensors.windSpeed, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "winD":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, windDirection:{
                            ...prevSensors.windDirection, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, windDirection:{
                            ...prevSensors.windDirection, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "uvpo":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, uvPower:{
                            ...prevSensors.uvPower, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, uvPower:{
                            ...prevSensors.uvPower, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "gust":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, gustSpeed:{
                            ...prevSensors.gustSpeed, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, gustSpeed:{
                            ...prevSensors.gustSpeed, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "daiR":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, dailyRainfall:{
                            ...prevSensors.dailyRainfall, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, dailyRainfall:{
                            ...prevSensors.dailyRainfall, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
            case "houR":
                if(type === "N"){
                    setSensors( prevSensors => ({
                        ...prevSensors, hourlyRainfall:{
                            ...prevSensors.hourlyRainfall, node_id: String(e.target.value)
                        }
                    }))
                }else{
                    setSensors( prevSensors => ({
                        ...prevSensors, hourlyRainfall:{
                            ...prevSensors.hourlyRainfall, sensor_id: String(e.target.value)
                        }
                    }))
                }
                break;
                
        }
    }
    const changeDevice = (e) =>{
        setSensors({...sensors, [e.target.name]: String(e.target.value)});
    }
    const changePublic = (e)=>{
        setPublicG(e.target.value);
    }
    const changeVegOption = (e) =>{
        setVegOption({...vegOption, [e.target.name]: e.target.value})
    }
    const addVegOptionB = () =>{
        if(vegOption.name !== '' && parseInt(vegOption.quantity) > 0){
            const toput = {
                name: vegOption.name,
                quantity: parseInt(vegOption.quantity)
            }
            let newarray = vegArray;
            newarray.push(toput);
            setVegArray(newarray);
            setCountlist(countlist+1);
        }
        console.log(vegArray);
    }
    const removeVegOptionB = (index) =>{
        let newarray = vegArray;
        newarray.splice(index, 1);
        setVegArray(newarray);
        setCountlist(countlist-1);
        console.log(vegArray);
    }
    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt="" />{t('universal_logout')}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t('createG_add')}</h2>
                    </div>
                    <div className='addgardenfield'>
                        <span className='addgardenfieldtitle'>{t('createG_title')}</span>
                        <span className='addgardenfieldlabel'>{t('createG_titleExpl')}</span>
                        <div className='addgardenfieldRest'>
                            <input type="text" className="inputsG" placeholder='MyGarden' value={title} onChange={(e)=>setTitle(e.target.value)}/>
                        </div>
                    </div>
                    <div className='addgardenfield'>
                        <span className='addgardenfieldtitle'>{t('createG_veg')}</span>
                        <span className='addgardenfieldlabel'>{t('createG_vegExpl')}</span>
                        <div className='addgardenfieldRest'>
                            <input type="text" className="inputsG" placeholder='Vegetable/Herb' name='name' value={vegOption.name} onChange={changeVegOption}/>
                            <input type="number" min="0" className="inputsN" placeholder='Quantity' name='quantity' value={vegOption.quantity} onChange={changeVegOption}/>
                            <img src={addcircle} alt='add' className='addimg' onClick={addVegOptionB}/>
                        </div>
                        <div className='addgardenfieldArray'>
                            {
                                vegArray.length > 0 &&
                                <>
                                    <div className='vegArrayDataH'>
                                        <div>{t('createG_name')}</div>
                                        <div>{t('createG_quant')}</div>
                                        <div>{t('createG_rm')}</div>
                                    </div>
                                    {
                                    vegArray.map((veg, key)=>(
                                        <div className='vegArrayData' key={key}>
                                            <div>{veg.name}</div>
                                            <div>{veg.quantity}</div>
                                            <div><img src={remove} alt='remove' className='deleteimg' onClick={()=>(removeVegOptionB(key))} /></div>
                                        </div>
                                    ))
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className='addgardenfield'>
                        <span className='addgardenfieldtitle'>{t('createG_codes')}</span>
                        <span className='addgardenfieldlabel'>{t('createG_codesExpl')}</span>
                        <div className='sensorFields'>
                            <span className='titleCodes'>{t('createG_crop')}</span>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_cropD')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Device ID' name='cropDevice' value={sensors.cropDevice} onChange={changeDevice}/>
                                </div>
                            </div>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_soilD')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Sensor' name='croMS' value={sensors.cropMoisture.sensor_id}  onChange={changeSensor}/>
                                </div>
                            </div>
                            <span className='titleCodes'>{t('createG_compost')}</span>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_compostD')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Device ID' name='compostDevice' value={sensors.compostDevice} onChange={changeDevice}/>
                                </div>
                            </div>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_compostTemp')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Sensor' name='comTS' value={sensors.compostTemperature.sensor_id}  onChange={changeSensor}/>
                                </div>
                                <div className='variableS'>
                                    <span>{t('createG_compostMoi')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Sensor' name='comMS' value={sensors.compostMoisture.sensor_id}  onChange={changeSensor}/>
                                </div>
                            </div>
                            <span className='titleCodes'>{t('createG_wt')}</span>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_wtDev')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Device ID' name='waterTankDevice' value={sensors.waterTankDevice} onChange={changeDevice}/>
                                </div>
                            </div>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_wtL')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Tank Lt' name='waterTanklt' value={waterTankLt} onChange={(e)=>(setWaterTankLt(e.target.value))}/>
                                </div>
                            </div>
                            <div className='sensorFieldsVar'>
                                <div className='variableS'>
                                    <span>{t('createG_tds')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Sensor' name='TDSsS' value={sensors.TDS.sensor_id}  onChange={changeSensor}/>
                                </div>
                                <div className='variableS'>
                                    <span>{t('createG_wtD')}</span>
                                    <input type="number" className="inputsSensor" placeholder='Sensor'  name='tankS' value={sensors.waterTank.sensor_id}  onChange={changeSensor}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='addgardenfield'>
                        <span className='addgardenfieldtitle'>{t('createG_loc')}</span>
                        <span className='addgardenfieldlabel'>{t('createG_locExpl')}</span>
                        <div className='addgardenfieldRest'>
                            {
                                location
                                &&
                                <>
                                <div className='latlonGarden'>
                                    <div>
                                        <span>{t('createG_lat')}</span>
                                        <input type="text" className="inputsG" disabled={"disabled"} placeholder={location[0]}/>
                                    </div>
                                    <div>
                                        <span>{t('createG_lon')}</span>
                                        <input type="text" className="inputsG" disabled={"disabled"} placeholder={location[1]}/>
                                    </div>
                                </div>
                                <FindLocationMap location={location} setLocation={setLocation} />
                                </>

                            }
                        </div>
                    </div>
                    {
                        isAdmin().admin &&
                        <div className='addgardenfield'>
                            <span className='addgardenfieldtitle'>{t('createG_public')}</span>
                            <span className='addgardenfieldlabel'>{t('createG_publicExpl')}</span>
                            <div className='addgardenfieldRest'>
                                <select className="inputsG" onChange={changePublic} >
                                    <option value={false}>No</option>                                
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                    }
                    
                    <div className='errorCont'><span className='errorCreate'>{error}</span></div>
                    <div className='buttonsCont'><div onClick={sendData}>{t('create_addG')}</div><div className='hiden'></div></div>
                </section>
            </div>
        </>
    );
};


export default CreateGarden;
/*
previous inputs
<input type="text" className="inputsSensor" placeholder='Node' name='croMN' value={sensors.cropMoisture.node_id} onChange={changeSensor}/>
<input type="text" className="inputsSensor" placeholder='Node'  name='comTN' value={sensors.compostTemperature.node_id} onChange={changeSensor}/>
<input type="text" className="inputsSensor" placeholder='Node' name='comMN' value={sensors.compostMoisture.node_id} onChange={changeSensor}/>
<input type="text" className="inputsSensor" placeholder='Node'  name='TDSsN' value={sensors.TDS.node_id} onChange={changeSensor}/>
<input type="text" className="inputsSensor" placeholder='Node' name='tankN' value={sensors.waterTank.node_id} onChange={changeSensor}/>

*/