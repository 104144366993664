import React, {useEffect, useState} from 'react';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogOut from '../Modules/LogOut';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import '../Css/Pages/PublicGardens.css';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import DashboardMap from '../Components/DashboardMap';
import InformationGardenPublic from '../Components/InformationGardenPublic';
import useWindowDimensions from '../Modules/useWindowDimensions';
import { useTranslation } from 'react-i18next';

const PublicGardens = () => {
    console.log("gardens")
    const[publicFields, setPublicFields] = useState([]);
    const[loader, setLoader] = useState(true);
    const[error, setError] = useState("");
    const[selectedGarden, setSelectedGarden] = useState();
    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();


    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        let mounted = true;
        const fetchData = async () => {
            setLoader(true);
            try{
                const res = await axios.get(BringUrl()+'/api/gardens/publicgardens', userAuth());
                if(mounted) {
                    setPublicFields(res.data);
                    console.log(res.data);
                    setError("")
                    setLoader(false);
                    console.log(res.data[0])
                    if(res.data.length > 0){
                        setSelectedGarden(res.data[0]);
                    }
                }
            }catch(err){
                console.log(err);
                setError("Error on loading the data");
                setLoader(false);
            }
        };
        fetchData();
        return () => {
            mounted = false;
        }
    },[]);

    const[menu , setMenu] = useState(true);


    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }

    const changeGarden = (e) =>{
        setSelectedGarden(publicFields[e.target.value]);
    }

    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg} className="sideImage" alt="" /> {t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <div className="PageHead">
                        <h2 className="PageTitle">{t("publicG_pub")}</h2>
                        <div className='privatemidrow'>
                            <div className='selectGarden'>
                                {
                                    publicFields &&
                                    <>
                                    <span>{t("publicG_sel")}</span>
                                    <select className='selectDrop' onChange={changeGarden}>
                                        {
                                            publicFields.map((garden, key)=>(
                                                <option key={key} value={key}>{garden.name}</option>
                                            ))
                                        }
                                    </select>
                                    </>
                                }
                            </div>
                            <div className='createGarden'></div>
                        </div>
                        <hr className="allHR"/>
                    </div>
                    <section className='privateGardenContainer'>
                    {
                        loader
                        ?<div className="circularProgressContainer"><CircularProgress size="60px" /></div>
                        :error !== ""
                        ?<span className='somethingWrong'>Something went wrong.</span>
                        :publicFields.length === 0
                        ?<span className='noResults'>{t("publicG_noG")}.</span>
                        :<>
                            {
                                selectedGarden &&
                                <section className='dashboardgardensMap'>
                                {
                                    publicFields && <DashboardMap gardenData={publicFields} selected={selectedGarden} />
                                }
                                </section>
                            }
                            
                            <section className='dataInfoPublic'>
                                { selectedGarden && <InformationGardenPublic  gardenId={selectedGarden._id} /> }
                            </section>
                        </>
                    }
                    </section>
                    
                </section>
            </div>
        </>
    );
};


export default PublicGardens;

/*
?<div className="circularProgressContainer"><CircularProgress size="60px" /></div>
                        :error !== ""
                        ?<span className='somethingWrong'>Something went wrong.</span>
                        :publicFields.length
                        ?<span className='noResults'>There are no Public Gardens yet...</span>
                        :

                        */