import React, {useState} from 'react';
import CloseModal from '../../Images/CloseModal.svg';
import '../../Css/Modals/GeneralModal.css';
import '../../Css/Modals/Register.css';
import '../../Css/General.css';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import BringUrl from '../../Modules/BringUrl';
import { useTranslation } from 'react-i18next';

const Register = (props) =>{
    const { t, i18n } = useTranslation();

    const [registerfields, setRegister] = useState({
        email:"",
        password:"",
        username:"",
        repeatpassword:"",
        userlevel: "Novice",
        admin: false
    });
    const [serverResponse, setServerResponse] = useState("");
    const [loader, setLoader] = useState(false);


    if(!props.show){
        return null;
    }

    const setFields = (event) =>{
        setRegister({...registerfields,[ event.target.name]: event.target.value})
    }
    const checkPassword = () =>{
        if(registerfields.password !== registerfields.repeatpassword){
            setServerResponse("Passwords doesn't match");
        }else{
            setServerResponse("");
        }
    }

    function registerUser(datatoregister){
        axios.post(BringUrl()  + "/api/user/register" ,  datatoregister).then(res => {
            // do good things
            setLoader(false);
            console.log(res);
            props.setToken(res.data);
        })
        .catch(err => {
            console.log(err.response);
            setServerResponse(err.response.data);
            setLoader(false);
        })
    }
    const register = (e) =>{
        e.preventDefault();
        console.log(registerfields);
        if(registerfields.email !== "" || registerfields.password !== "" || registerfields.username !== ""){
            if(registerfields.password !== registerfields.repeatpassword){
                setServerResponse("Passwords doesn't match");
            }else{
                setServerResponse("");
                setLoader(true);
                const datatoregister = {
                    password: registerfields.password,
                    username: registerfields.username,
                    userlevel: registerfields.userlevel,
                    admin: registerfields.admin
                }
                console.log(datatoregister);
                registerUser(datatoregister)
            }
        }else{
            setServerResponse("There are empty fields");
        }
    }

    return(
        <div className={props.show ? "modalGeneral display-block" : "modalGeneral display-none"}>
            <section className="modal-mainPanel registerPanel">
                <div className="flexcolumncenterfull">
                    <h3>{t("Register")}</h3>
                    <div className="flexcolumntopfull registerFields">
                        <span className="credFields"><TextField name="username" label="Username" type="text" onChange={setFields} className="regField"/></span>
                        <span className="credFields"><TextField name="password" label="Password" type="password" className="regField" onChange={setFields} /></span>
                        <span className="credFields"><TextField name="repeatpassword" label="Repeat Password" type="password" className="regField" onChange={setFields} onBlur={checkPassword} /></span>
                        <select name="userlevel" className="userlevelDrop" onChange={setFields}>
                            <option value={"Novice"}>{t("user_nov")}</option>
                            <option value={"Advanced"}>{t("user_adv")}</option>
                            <option value={"Competent"}>{t("user_comp")}</option>
                            <option value={"Experienced"}>{t("user_expe")}</option>
                        </select>
                        
                        <span className="responseField serverresponseError">{serverResponse}</span>
                        {
                            loader ? <CircularProgress /> : <div className="LoginButton" onClick={register}>Register</div>
                        }
                    </div>
                </div>
                
                <img src={CloseModal} alt="close" className="closemodalV" onClick={props.hideModal} />
            </section>
        </div>
    );
}

export default Register;
/*
<select name="admin" className="userlevelDrop" onChange={setFields}>
    <option value={false}>Simple User</option>
    <option value={true}>Admin User</option>
</select>
*/