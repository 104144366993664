import React,{useEffect, useState} from 'react';
import InformationGarden from './InformationGarden';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';

const InformationGardenPublic = (props) => {

    const[garden, setGarden] = useState();

    useEffect(()=>{
        let mounted = true;
        const initialLoad = async () =>{
            try{
                const res = await axios.get(BringUrl() + "/api/gardens/publicgarden/"+props.gardenId , userAuth());
                if(mounted) {
                    setGarden(res.data);
                    console.log(res.data);
                }
            }catch(err){
                console.log(err);
            }
        }
        
        initialLoad();
        return () => {
            mounted = false;
        }
    },[props.gardenId])

    return (
        garden 
        ?
        <>
            <InformationGarden  data={garden.sensorData} />
        </>
        :null
    );
};


export default InformationGardenPublic;