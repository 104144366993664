import { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'





const FindLocationMap = (props) => {

  console.log("findLocation")

    return (
        <>
        {
            <MapContainer center={props.location} zoom={13} className="dragableMarker">
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker currentposition={props.location} setLocation={props.setLocation} />
            </MapContainer>
        }
        </>
      )
};


function DraggableMarker(props) {
    const [position, setPosition] = useState(props.currentposition);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
              console.log(marker.getLatLng());
            setPosition(marker.getLatLng());
            props.setLocation([marker.getLatLng().lat, marker.getLatLng().lng]);
          }
        },
      }),
      [],
    )
      console.log(position);
    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
      </Marker>
    )
}

export default FindLocationMap;