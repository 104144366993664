import React, {useState} from 'react';
import step1 from '../Images/tutorial/stepLogin.svg';
import step2 from '../Images/tutorial/settingsT.svg';
import step3 from '../Images/tutorial/gardening.svg';
import step4 from '../Images/tutorial/ForecastColor.svg';
import step5 from '../Images/tutorial/pGard.svg';
import step6 from '../Images/tutorial/transparency.svg';

import vim1 from '../Images/tutorial/s1.svg';
import vim2 from '../Images/tutorial/s2.svg';
import vim3 from '../Images/tutorial/s3.svg';
import vim4 from '../Images/tutorial/s4.svg';
import vim5 from '../Images/tutorial/s5.svg';
import vim6 from '../Images/tutorial/s6.svg';

import arrowB from "../Images/arrowb.svg";
import arrowF from "../Images/arrowf.svg";
import { useTranslation } from 'react-i18next';


const OneDirSteps = () => {

    const { t, i18n } = useTranslation();

    const [step, setStep] = useState(0);

    const cardData = [
        {
            title:t("dash_step1Title"),
            image: step1,
            step: vim1,
            text: t("dash_step1Text")
        },
        {
            title:t("dash_step2Title"),
            image: step2,
            step: vim2,
            text: t("dash_step2Text")
        },
        {
            title:t("dash_step3Title"),
            image: step3,
            step: vim3,
            text: t("dash_step3Text")
        },
        {
            title:t("dash_step4Title"),
            image: step4,
            step: vim4,
            text: t("dash_step4Text")
        },
        {
            title:t("dash_step5Title"),
            image: step5,
            step: vim5,
            text: t("dash_step5Text")
        },
        {
            title:t("dash_step6Title"),
            image: step6,
            step: vim6,
            text: t("dash_step6Text")
        }
    ];
    
    const changeStep = (mov) =>{
        if(mov === "forw"){
            setStep((step+1)%cardData.length);
        }else{
            if(step-1<0){
                setStep(cardData.length - Math.abs(step-1));
            }else{
                setStep(Math.abs(step-1)%cardData.length);
            }
        }

    }
    
    return (
        <div className='allsteps'>
            <div className='stepTemplate'>
                <div className='imagesContainer'>
                    <img src={arrowB} alt="swipe Left" className={step===0?'notDisplay':'arrows'} onClick={()=>(changeStep("bck"))} />
                    <div className='colT'><img src={cardData[step].image} alt='' className='mainStepImage' />  <span className='stepTitle'><img src={cardData[step].step} alt='' className='backStepImage' /> {cardData[step].title}</span> </div>
                    <img src={arrowF} alt="swipe Right" className={step===5?'notDisplay':'arrows'} onClick={()=>(changeStep("forw"))} />
                </div>
                <span className='steptext'>{cardData[step].text}</span>
            </div>
        </div>
    );
};


export default OneDirSteps;