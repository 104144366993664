import React,{useEffect, useState} from 'react';
import MapGarden from './MapGarden';
import '../Css/Components/GardenPrivate.css';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import InformationGarden from './InformationGarden';
import NotificationsDrop from './NotificationsDrop';
import GardenActions from './GardenActions';
import { useTranslation } from 'react-i18next';



const GardenPrivate = (props) => {
    const { t, i18n } = useTranslation();

    const[gardenData, setGardenData] = useState();
    const[error, setError] = useState(false);
    const[deleteNot, setDeleteN] = useState(0);
    const [trigUpdate, setTrig] = useState(0);

    useEffect(()=>{
        let mounted = true;
        const initialLoad = async () =>{
            try{
                const res = await axios.get(BringUrl() + "/api/gardens/garden/"+props.gardenId , userAuth());
                if(mounted) {
                    console.log(res.data);
                    setGardenData(res.data);
                    setError(false);
                }
            }catch(err){
                if(mounted) {
                    setError(true);
                }
            }
        }
        initialLoad();
        return (()=>{
            mounted = false;
        });
    },[props.gardenId , deleteNot, trigUpdate]);


    return (
        <section className='privateGardenComp'>
            {
                gardenData
                ?<>
                    
                    <section className='dataInfo'>
                        <InformationGarden  data={gardenData.sensorData} />
                    </section>
                    <section className='Gactions'>
                        <GardenActions data={gardenData.GardenInfo} trigUpdate={trigUpdate} setTrig={setTrig}  />
                    </section>
                    <section className='dataNotifications'>
                        <NotificationsDrop notifArray={gardenData.notifications} setDeleteN={setDeleteN} deleteNot={deleteNot} />
                    </section>
                </>
                :error
                ?<span> {t("error_codes")}</span>
                :<div className="circularProgressContainer"><CircularProgress size="60px" /></div>
            }
            
        </section>
    );
};


export default GardenPrivate;

/*
<section className='mapInfo'>
                        <div className='mapCont'>
                            <MapGarden position={gardenData.GardenInfo.coordinates} popUpName={gardenData.GardenInfo.name} popUpType={gardenData.GardenInfo.type}  />
                        </div>
                        <div className='infoCont'>
                            <div className='typeInfo'>
                                <span className='privateGFTitle'>{gardenData.GardenInfo.name}</span>
                                <img src={vigie} alt="image" />
                                <span className='privateGFVeg'>{gardenData.GardenInfo.type}</span>
                                <span className='privateGFDecription'>
                                    Lettuce (Lactuca sativa) is an annual plant of the daisy family, Asteraceae. It is most often grown as a leaf vegetable, but sometimes for its stem and seeds. Lettuce is most often used for salads, although it is also seen in other kinds of food, such as soups, sandwiches and wraps; it can also be grilled.
                                </span>
                            </div>
                        </div>
                    </section>
*/