import React, {useState, useEffect} from 'react';
import LogOut from '../Modules/LogOut';
import '../Css/Pages/Dashboard.css';
import SideBarMenu from '../Components/SideBar/SideBarMenu';
import '../Css/Menu.css';
import {sideData} from '../Components/SideBar/DashboardMenuData';
import HeaderDashboard from '../Components/HeaderDashboard';
import LogoutImg from '../Images/Logout.svg';
import DashboardMap from '../Components/DashboardMap';
import BringUrl from '../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import MovingCards from '../Components/MovingCards';
import OneDirSteps from '../Components/OneDirSteps';
import useWindowDimensions from '../Modules/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Context } from '../Context/context';

const Dashboard = () => {
    const[menu , setMenu] = useState(true);
    const value = useContext(Context);
    console.log(value.lang)
    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

    const logout = () =>{
        LogOut();
    }
    const switchMenu = () =>{
        setMenu(!menu);
    }

    const[gardenData, setGardenData] = useState();
    const[error, setError] = useState(false);

    useEffect(()=>{
        if(width<680){
            setMenu(false);
        }
        let mounted = true;
        const initialLoad = async () =>{
            try{
                const res = await axios.get(BringUrl() + "/api/gardens/publicprivate" , userAuth());
                if(mounted) {
                    console.log(res.data);
                    setGardenData(res.data);
                    setError(false);
                }
            }catch(err){
                if(mounted) {
                    setError(true);
                }
            }
        }
        initialLoad();
        return (()=>{
            mounted = false;
        });
    },[]);

    

    return (
        <>
            <HeaderDashboard menu={menu} switchMenu={switchMenu} />
            <div className="mainmain">
                <section className={menu && width?"sideBar":"sideBarClose"}>
                    <SideBarMenu sidedata={sideData} />
                    <span className="logOut" onClick={logout}><img src={LogoutImg}  alt=""  className="sideImage" /> {t("universal_logout")}</span>
                </section>
                <section className={menu?"mainmainmain":"mainmainmainClose"}>
                    <section className='dashboardgardensMap'>
                        <h2 className='PageTitle'>{t("dash_over")}</h2>
                        <h3>{t("dash_map")}</h3>  
                        {
                            gardenData && <DashboardMap gardenData={gardenData} />
                        }
                    </section>
                    <section className='dashboardgardensTips'>
                        <section className='tipoftheday'>
                            <h3>{t("dash_tip")}</h3>
                            <MovingCards />
                        </section>
                        <section className='stepContainer'>
                            <section className='steps'>
                                <h3>{t("dash_tutorial")}</h3>
                                <OneDirSteps />
                            </section>
                        </section>
                    </section>
                </section>
            </div>
        </>
    );
};


export default Dashboard;