import React,{useEffect, useState} from 'react';
import BringUrl from '../../Modules/BringUrl';
import axios from 'axios';
import {userAuth} from '../../Modules/AuthConfigs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Plot from 'react-plotly.js';


const DataChart = (props) => {

    console.log("chart")
    const[gardenData, setGardenData] = useState();
    const[error, setError] = useState(false);


    function bringDate(i){
        let event = new Date();
        event.setDate(event.getDate() - i);
        return event.toISOString().substring(0,10);
    }

    const dates = [bringDate(0), bringDate(1), bringDate(2), bringDate(3), bringDate(4), bringDate(5), bringDate(6), bringDate(7), bringDate(8), bringDate(9),
                   bringDate(10), bringDate(11), bringDate(12), bringDate(13), bringDate(14), bringDate(15), bringDate(16), bringDate(17), bringDate(18), bringDate(19),
                   bringDate(20), bringDate(21), bringDate(22), bringDate(23), bringDate(24), bringDate(25), bringDate(26), bringDate(27), bringDate(28), bringDate(29)];


    useEffect(()=>{
        let mounted = true;
        const initialLoad = async () =>{
            try{
                const res = await axios.get(BringUrl() + "/api/gardens/gardenChart/"+props.gardenId , userAuth());
                if(mounted) {
                    console.log(res.data);
                    setGardenData(res.data);


                    setError(false);
                }
            }catch(err){
                if(mounted) {
                    setError(true);
                }
            }
        }
        initialLoad();
        return (()=>{
            mounted = false;
        });
    },[props.gardenId]);


    

    
    return (
        <>
            {
                gardenData ? <Chart gardenData={gardenData}  dates={dates} /> :<CircularProgress />
            }
        </>
    );

};



export default DataChart;

const Chart = (props) => {

    useEffect(()=>{
        console.log("render",props)
    },[props.gardenData]);
    
    let layout = {
        title:'Last 30 days metrics ',
        width: 1200,
        height: 600,
        xaxis: {
            title: 'Days',
            fixedrange: true
        },
        yaxis: {
            title: 'Values',
            fixedrange: true
        }
      };

    let cropMoisture = {
        x: props.dates,
        y: props.gardenData.cropMoisture,
        mode: 'lines+markers',
        name: 'Crop Moisture'
    };
    let compostTemp = {
        x: props.dates,
        y: props.gardenData.compostTemperature,
        mode: 'lines+markers',
        name: 'Compost Temperature'
    };
    let compostMoi = {
        x: props.dates,
        y: props.gardenData.compostMoisture,
        mode: 'lines+markers',
        name: 'Crop Moisture'
    };
    let watertank = {
        x: props.dates,
        y: props.gardenData.waterTank,
        mode: 'lines+markers',
        name: 'Water Tank'
    };
    let tds = {
        x: props.dates,
        y: props.gardenData.TDS,
        mode: 'lines+markers',
        name: 'TDS'
    };
    
    const data = [cropMoisture, compostTemp, compostMoi, watertank, tds];

    return(
        <div style={{marginTop: '20px'}}>
            <Plot data={data} layout={ layout } />  
        </div>
    );
}