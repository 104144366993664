import React, {useState} from 'react';
import arrowL from "../Images/arrowL.svg";
import arrowR from "../Images/arrowR.svg";
import { useTranslation } from 'react-i18next';


const MovingCards = () => {

    const [step, setStep] = useState(0);
    const { t, i18n } = useTranslation();

    const cardData = [
        {
            title: t("dash_wtinftitle") +" 1",
            text: t("dash_wt1")
        },
        {
            title:t("dash_wtinftitle")+" 2",
            text: t("dash_wt2")
        },
        {
            title: t("dash_wtinftitle")+" 3",
            text: t("dash_wt3")
        },
        {
            title: t("dash_compinftitle")+" 1",
            text: t("dash_com1")
        },
        {
            title: t("dash_compinftitle")+" 2",
            text: t("dash_comp2")
        },
        {
            title: t("dash_compinftitle")+" 3",
            text: t("dash_comp3")
        },
        {
            title: t("dash_compinftitle")+" 4",
            text: t("dash_comp4")
        },
        {
            title: t("dash_compinftitle")+" 5",
            text: t("dash_comp5")
        },
        {
            title: t("dash_compinftitle")+" 6",
            text: t("dash_comp6")
        },
        {
            title: t("dash_cropinftitle")+" 1",
            text:t("dash_crop1")
        },
        {
            title: t("dash_cropinftitle")+" 2",
            text:t("dash_crop2")
        },
        {
            title: t("dash_cropinftitle")+" 3",
            text:t("dash_crop3")
        },
        {
            title: t("dash_cropinftitle")+" 4",
            text:t("dash_crop4")
        },
        {
            title: t("dash_cropinftitle")+" 5",
            text:t("dash_crop5")
        },
        {
            title: t("dash_cropinftitle")+" 6",
            text:t("dash_crop6")
        }
    ]


    const changeStep = (mov) =>{
        if(mov === "forw"){
            setStep((step+1)%cardData.length);
        }else{
            if(step-1<0){
                setStep(cardData.length - Math.abs(step-1));
            }else{
                setStep(Math.abs(step-1)%cardData.length);
            }
        }

    }
    return (
        <div className='cardTemplate'>
            <div className='imgRow'>
                <img src={arrowL} alt="swipe Left" className='arrows' onClick={()=>(changeStep("bck"))} />
                <span>{cardData[step].title}</span>
                <img src={arrowR} alt="swipe Right" className='arrows' onClick={()=>(changeStep("forw"))} />
            </div>
            <div className='headtext'>
                <span>{cardData[step].text}</span>
            </div>
        </div>
    );
};


export default MovingCards;