import React, { useState} from 'react';
import '../Css/Landing.css';
import Hamb from '../Images/MenuButton.svg';
import '../Css/Components/Login.css';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import BringUrl from '../Modules/BringUrl';
import Register from '../Components/Modals/Register.js'
import useToken from '../Modules/useToken';
import BringUrlLocale from '../URLs/BringUrlLocale';
import Circ4Food from '../Images/CIRC4Food_Logo.svg';
import LandingImg from '../Images/LandingImg.svg';
import Ports from '../URLs/Ports.json';
import { useTranslation } from 'react-i18next';

const Landing = () => {

    const[page, setPage] = useState(0);
    const[ham, setHam] = useState(false);

    const { token, setToken } = useToken();
    const { t, i18n } = useTranslation();

    const bringContent = (pg) =>{
        switch(pg){
            case 1:
                return <Login register={false} setToken={setToken}  />
            case 2:
                return <Login register={true}  setToken={setToken}  />
            case 3:
                return <About />
            case 4:
                return <VisionScope />
            case 5:
                return <VisionScope />
            default:
                return <Main setPage={setPage} />
        }
    }
    
    return (
        <div className="waveContainer">
            <header className="headerLand">
                <div className="headerLeft">
                    <img src={Circ4Food} alt="Logo" className="iccsLogo" onClick={()=>(setPage(0))}/>
                </div>
                <div className="headerRight">
                    <div className="LandingMenu">
                        <span onClick={()=>(setPage(0))} >{t('landing_about')}</span>
                        <span onClick={()=>(setPage(1))}>{t('landing_login')}</span>
                        <div className="register" onClick={()=>(setPage(2))} >{t('landing_register')}</div>
                    </div>
                    <div className="LandingMenuMobile">
                        <img src={Hamb} alt="menu" className="hambButtonLand" onClick={()=>(setHam(!ham))} />
                    </div>
                </div>
                {
                    ham &&
                    <div className="mobileMenuLand">
                        <span onClick={()=>(setPage(0))} >{t('landing_about')}</span>
                        <span onClick={()=>(setPage(1))}>{t('landing_login')}</span>
                        <span  onClick={()=>(setPage(2))} >{t('landing_register')}</span>
                    </div>
                }
            </header>
            <div className="LandingMain">
                {bringContent(page)}
            </div>
        </div>
    );
};


export default Landing;


const Login = ({ setToken  , register}) => {

    
    const [serverResponse, setServerResponse] = useState("");
    const [loader, setLoader] = useState(false);
    const [credentials, setCredentials] = useState({
        username:"",
        password:""
    });
    const[registerPanel, setRegisterPanel] = useState(register);
    const { t, i18n } = useTranslation();


    const triggRegister = () =>{
        setRegisterPanel(!registerPanel);
    }

    

    function loginUser(credentials) {
        axios.post(BringUrl()  + "/api/user/login" ,  credentials).then(res => {
            // do good things
            setLoader(false);
            console.log(res);
            setToken(res.data);
        })
        .catch(err => {
            console.log(err.response);
            setServerResponse(err.response.data);
            setLoader(false);
        });
        
    }

    const login = (e) =>{
        e.preventDefault();
        console.log(credentials);
        if(credentials.username !== "" && credentials.password !== ""){
            setLoader(true)
            loginUser(credentials);
        }else{
            setServerResponse("You have empty field.");
        }
        
        
    }
    const setFields = (event) =>{
        setCredentials({...credentials,[ event.target.name]: event.target.value})
    }

    if(sessionStorage.getItem("token")){
        window.location.replace(BringUrlLocale() + Ports.UI + "/dashboard");
    }
    return (
        <div className="loginPage">
            <div className="mainUserPanel">
                <span><img src={Circ4Food} alt="Logo Icon" className="LoginLogo" /></span>
                <div className="credentials">
                    <span className="credFields"><TextField name="username" label="Username" type="text" onChange={setFields} /></span>
                    <span className="credFields"><TextField name="password" label="Password" type="password" className="credFields" onChange={setFields} /></span>

                    <span className="responseField serverresponseError">{serverResponse}</span>
                    {
                        loader ? <CircularProgress /> : <div className="LoginButton" onClick={login}>Login</div>
                    }
                    <div className="Registerhere">{t('landing_dontAcc')}<span onClick={triggRegister}>{t('landing_registerHere')}</span></div>
                    <div className="contacthelp">{t('landing_ifProbl')}<span>marios.palazis@iccs.gr</span></div>
                </div>
            </div>
            <Register show={registerPanel} hideModal={triggRegister} setToken={setToken} />
        </div>
    );
    
};

const Main = () =>{
    const { t, i18n } = useTranslation();


    return(
        <>
            <div className="MainPageLand">
                <div className="topwave">
                    <img src={LandingImg} alt="" className="landImg" />
                    <div className="landWelcome">
                        <span className="landTitle">{t("landing_welcome")}</span>
                        <span className="landmain">{t("landing_welcometext")}</span>
                    </div>
                </div>
                <div className="bottomwave">

                </div>
            </div>
        </>
    );
}

const About = () =>{


    return(
        <span>About</span>
    );
}

const VisionScope = () =>{


    return(
        <span>VisionScope</span>
    );
}
/*

                        <span onClick={()=>(setPage(4))}>How-to</span>
                        <span onClick={()=>(setPage(5))}>Contact</span>


                        <span onClick={()=>(setPage(4))}>How-to</span>
                        <span onClick={()=>(setPage(5))}>Contact</span>
*/