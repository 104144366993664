import React,{ useContext} from 'react';
import Circ4Food from '../Images/CIRC4Food_Logo.svg';
import User from '../Images/UserProfile.svg';
import Hamb from '../Images/MenuButton.svg';
import Notifications from '../Images/notifications.svg';
import '../Css/Pages/Dashboard.css';
import CloseButton from '../Images/CloseButton.svg';
import { Link } from 'react-router-dom';
import { Context } from '../Context/context';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next'

const HeaderDashboard = (props) => {
    const { lang, setLang } = useContext(Context);
    const { t, i18n } = useTranslation()

    const handleChange = (e) =>{
        setLang(e.target.value);
        i18n.changeLanguage(e.target.value)
    }

      
    return (
        <header className="DashboardHead">
            <div className={props.menu?"hambButtonDash":"hambButtonDashClosed"}> <img src={props.menu?CloseButton:Hamb} alt="menu" onClick={props.switchMenu} className="hamb" /></div><img src={Circ4Food} alt="Logo" className="DashLogo"/>
            <div className="notificationSection">
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    className='LangClass'
                >
                    <ToggleButton value="en">En</ToggleButton>
                    <ToggleButton value="gr">Gr</ToggleButton>
                </ToggleButtonGroup>
                <Link to={"/dashboard/userInfo"}><img src={User} alt="User" className="UserDash"/></Link>
            </div>
        </header>
    );
};
/*

    <img src={Notifications} alt="Notifications" className="Notifications"/>
    <div className="NotificationsNum">3</div>
*/


export default HeaderDashboard;