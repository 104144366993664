import React, {useEffect, useState, useContext} from 'react';
import '../../Css/Components/SideBarMenu.css';
import { Link } from 'react-router-dom';
import { Context } from '../../Context/context';
import { sideDataEN, sideDataGR } from './DashboardMenuData';

const SideBarMenu = (props) => {

    const[admin, setAdmin] = useState(false);
    const { lang } = useContext(Context);
    console.log(lang)
    useEffect(()=>{
        const token = sessionStorage.getItem("token");
        setAdmin(JSON.parse(token).admin);
    },[])


    return (
        <div className="sideBarContainer">
            {
                lang === "en"
                ?
                sideDataEN?.map((men, key)=>{
                    if(men.userView || admin){
                        return(<Link to={men.path} className="links" key={key}><div className="option" ><img src={men.image} alt="" className="sideImage" />  {men.title}</div></Link>);
                    }
                })
                :
                sideDataGR?.map((men, key)=>{
                    if(men.userView || admin){
                        return(<Link to={men.path} className="links" key={key}><div className="option" ><img src={men.image} alt="" className="sideImage" />  {men.title}</div></Link>);
                    }
                })
            }
        </div>
    );
};


export default SideBarMenu;