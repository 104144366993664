import React, {useState } from 'react';
import '../Css/Components/Login.css';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import BringUrl from '../Modules/BringUrl';
import Register from '../Components/Modals/Register.js'
import BringUrlLocale from '../URLs/BringUrlLocale';
import Ports from '../URLs/Ports.json';


const Login = ({ setToken , setTokenAPI}) => {

    
    const [serverResponse, setServerResponse] = useState("");
    const [loader, setLoader] = useState(false);
    const [credentials, setCredentials] = useState({
        username:"",
        password:""
    });
    const[registerPanel, setRegisterPanel] = useState(false);


    const triggRegister = () =>{
        setRegisterPanel(!registerPanel);
    }

    

    function loginUser(credentials) {
        axios.post(BringUrl()  + "/api/user/login" ,  credentials).then(res => {
            // do good things
            console.log(1);
            setLoader(false);
            console.log(res);
            setToken(res.data);
        })
        .catch(err => {
            console.log(err.response);
            setServerResponse("Wrong Username/Password");
            setLoader(false);
        });
        
    }

    const login = (e) =>{
        e.preventDefault();
        console.log(credentials);
        if(credentials.username !== "" && credentials.password !== ""){
            setLoader(true)
            loginUser(credentials);
        }else{
            setServerResponse("You have empty field.");
        }
        
        
    }
    const setFields = (event) =>{
        setCredentials({...credentials,[ event.target.name]: event.target.value})
    }


    if(sessionStorage.getItem("token")){
        window.location.replace(BringUrlLocale()+Ports.UI+"/dashboard");
    }

    return (
        <div className="loginPage">
            <div className="mainUserPanel">
                <span><img src={""} alt="Logo Icon Here" className="LoginLogo" /></span>
                <div className="credentials">
                    <span className="credFields"><TextField name="username" label="Username" type="text" onChange={setFields} /></span>
                    <span className="credFields"><TextField name="password" label="Password" type="password" className="credFields" onChange={setFields} /></span>

                    <span className="responseField serverresponseError">{serverResponse}</span>
                    {
                        loader ? <CircularProgress /> : <div className="LoginButton" onClick={login}>Login</div>
                    }
                    <div className="Registerhere">Don't have an account? <span onClick={triggRegister}>Register here</span></div>
                </div>
            </div>
            <Register show={registerPanel} hideModal={triggRegister} setToken={setToken} />
        </div>
    );
    
};


export default Login;