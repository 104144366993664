import React,{useEffect, useState} from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

const DashboardMap = (props) => {

    console.log(props.gardenData)
    return (
        <>
            {
                props.gardenData &&
                <MapContainer center={props.gardenData.length>0 ? props.gardenData[0].coordinates : {lat: '39.549179024306255', lon: '21.76566495339256'}} zoom={13} scrollWheelZoom={true} className="dashboardmap" >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        props.gardenData.map((garden, key)=>(
                            <Marker position={garden.coordinates} key={key}  >
                                <Popup className='popUp'>
                                    <div className='popUpContainer'>
                                        <span className='popUpTitle'>{garden.name}</span>
                                        <span>{garden.public?"Public Garden":"Private Garden"}</span>
                                        
                                    </div>
                                </Popup>
                            </Marker>
                        ))
                    }
                </MapContainer>
            }
        </>
    );
};


export default DashboardMap;